import React, { type ChangeEvent, type ClipboardEvent, useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';

import { range } from '../../helpers';
import Translate from '../Translate';

import styles from './VerificationCodeInput.module.scss';

interface Props {
  value: string;
  length: number;
  className?: string;
  clearOnError?: boolean;
  error?: boolean | string | React.ReactNode;
  hint?: string | React.ReactNode;
  autoFocus?: boolean;

  onChange(value: string): void;
}

export function VerificationCodeInput(props: Props) {
  const ref = useRef<HTMLInputElement[]>([]);
  const handledError = useRef(false);
  const [value, setValue] = React.useState(() => {
    const value = props.value.split('') ?? [];
    if (value.length < props.length) {
      return value.concat(new Array(props.length - value.length).fill(''));
    }
    return value;
  });

  const handleChange = useCallback(
    (index: number, input: string) => {
      if (!input.match(/\d?/)) return;
      const currentValue = [...value];
      const nextValue = [...currentValue.slice(0, index), input, ...currentValue.slice(index + 1, props.length - 1)];
      setValue(nextValue);

      if (index + 1 === props.length) {
        props.onChange(nextValue.join(''));
        handledError.current = false;
        return;
      }
      if (ref.current[index + 1] && input !== '') ref.current[index + 1].focus();
    },
    [props.length, value],
  );

  const handleInput = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value.trim();
    handleChange(index, eventValue);
  };

  const handleKeyUp = useCallback(
    (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace' && index > 0 && value[index] === '') {
        handleChange(index - 1, '');
        ref.current[index - 1].focus();
      }
    },
    [value],
  );

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const value = (event.clipboardData || window.clipboardData).getData('text');
    if (value.length !== props.length) return;
    props.onChange(value);
  };

  const handleFocus = () => {
    if (!props.clearOnError) return;
    if (props.error && !handledError.current) {
      props.onChange('');
      setValue(new Array(props.length).fill(''));
      handledError.current = true;
    }
  };

  useEffect(() => {
    if (props.value.length === props.length && props.value === value.join('')) return;

    let propsValue = props.value.split('') ?? [];
    if (propsValue.length < props.length) {
      propsValue = propsValue.concat(new Array(props.length - propsValue.length).fill(''));
    }
    setValue(propsValue);
  }, [props.value]);

  return (
    <label className={cn(styles.container, props.className, { error: props.error })}>
      <div className={styles.input}>
        {range(0, props.length).map(index => (
          <input
            autoFocus={props.autoFocus && index === 0}
            key={`code-input-${index}`}
            onChange={handleInput(index)}
            onFocus={handleFocus}
            onKeyUp={handleKeyUp(index)}
            onPaste={handlePaste}
            ref={e => {
              ref.current[index] = e;
            }}
            type="text"
            value={value[index] ?? ''}
          />
        ))}
      </div>
      {props.error && props.error !== true ? (
        <span className={styles.error}>
          {typeof props.error === 'string' ? <Translate id={props.error} optional /> : props.error}
        </span>
      ) : props.hint ? (
        <span className={styles.hint}>
          {typeof props.hint === 'string' ? <Translate id={props.hint} optional /> : props.hint}
        </span>
      ) : null}
    </label>
  );
}
