import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, FormikProvider, useField, useFormik } from 'formik';
import { bool, object, string } from 'yup';

import { useEnum } from '../../hooks/useEnum';
import { usePageView } from '../../hooks/useTracking';
import type { Consents } from '../../model/Consents';
import { mapAttributesToValueLabelPairs, sortByAlphabet } from '../../model/Enum';
import { completeCountry, type UserRegistrationSteps } from '../../modules/UserRegistration/SumsubUserRegistration';
import { type Country, supportedCountries } from '../../services/Country';
import Tracking from '../../services/Tracking';
import FormikSubmitButton from '../Form/SubmitButton';
import { Checkbox } from '../Input/Checkbox';
import { RadioGroup } from '../Input/RadioGroup';
import type { SwitchChildrenProps } from '../Switch';
import T from '../Translate';
import WizardNavigation from '../WizardNavigation';

import styles from './UserRegistrationCountry.module.scss';

interface FormValues {
  country: Country;
  consents: Consents;
}

export default function UserRegistrationCountry(_: SwitchChildrenProps<UserRegistrationSteps>) {
  const dispatch = useDispatch();
  const [consentDefinition] = useEnum('consents');
  const [regions] = useEnum('regions');
  const options = sortByAlphabet(mapAttributesToValueLabelPairs(regions));

  let consentScheme = object().nullable();
  for (const [country, definition] of Object.entries(consentDefinition)) {
    const consents = {};
    let hasRequired = false;
    for (const [code, required] of Object.entries(definition.schema)) {
      consents[code] = required ? bool().oneOf([true], 'global.required') : bool().optional();
      if (required) {
        hasRequired = true;
      }
    }
    consentScheme = consentScheme.when('country', {
      is: country,
      then: () => (hasRequired ? object(consents).nullable().required() : object(consents).optional()),
    });
  }

  const schema = object({
    country: string().nullable().required('global.required').oneOf(supportedCountries),
    consents: consentScheme,
  });

  const formik = useFormik<FormValues>({
    validationSchema: schema,
    initialValues: {
      country: null,
      consents: {},
    },
    async onSubmit(values) {
      try {
        await dispatch(completeCountry(values.country, values.consents));
        Tracking.track('USER_REG_COUNTRY_COMPLETED', values.country);
      } catch (error) {
        //
      }
    },
  });

  usePageView('signup_countryconsents');

  return (
    <FormikProvider value={formik}>
      <Form className="main-container">
        <main className="container">
          <WizardNavigation />

          <div className="container container--md">
            <T as="h1" className="h4" id="user.registration.country.title" />
            <T as="p" className="sm mb-4" id="user.registration.country.description" />

            <RadioGroup
              error={!!formik.errors.country}
              formatLabel={option => (
                <div className={styles.label}>
                  <i className={`flag flag-${option.value.toLowerCase()}`} />
                  <span>{option.label}</span>
                </div>
              )}
              onChange={country => {
                formik.resetForm({
                  values: {
                    country,
                    consents: consentDefinition[country].initialValues,
                  },
                  errors: {},
                  touched: { country: true },
                  isSubmitting: false,
                  isValidating: false,
                  submitCount: 0,
                });
              }}
              options={options}
              value={formik.values.country}
            />

            <div className="mt-5">
              {formik.values.country ? (
                <>
                  <ConsentsView country={formik.values.country} />
                  <ConsentNoticesView country={formik.values.country} />
                </>
              ) : null}
            </div>

            <FormikSubmitButton className="button button-primary button-block button-icon button-lg d-flex justify-content-center">
              <T id="user.registration.country.submit" />
              <i className="icon icon-arrow-right-circle" />
            </FormikSubmitButton>
          </div>
        </main>
      </Form>
    </FormikProvider>
  );
}

const isNotEmpty = (it?: string) => typeof it === 'string' && it !== '';

function ConsentsView({ country }: { country: Country }) {
  const [definition] = useEnum('consents');
  const [field, meta, helpers] = useField<Consents>('consents');

  if (!(country in definition)) return null;

  return (
    <div className={styles.consents}>
      {definition[country].sections.map(section => (
        <React.Fragment key={section.code}>
          {isNotEmpty(section.title) ? (
            <p className={styles.sectionTitle} dangerouslySetInnerHTML={{ __html: section.title }} />
          ) : null}
          {section.consents.map(it => (
            <Checkbox
              checked={it.code in field.value && field.value[it.code]}
              className="secondary"
              error={it.required && meta.touched && (!(it.code in field.value) || !field.value[it.code])}
              key={it.code}
              name={`consents.${it.code}`}
              onChange={checked => helpers.setValue({ ...field.value, [it.code]: checked }, true)}
              value={it.code}
            >
              <span className={styles.item} dangerouslySetInnerHTML={{ __html: it.title }} />
              {isNotEmpty(it.description) ? (
                <p className={styles.description} dangerouslySetInnerHTML={{ __html: it.description }} />
              ) : null}
            </Checkbox>
          ))}
          {isNotEmpty(section.note) ? (
            <p className={styles.note} dangerouslySetInnerHTML={{ __html: section.note }} />
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
}

function ConsentNoticesView({ country }: { country: Country }) {
  const [definition] = useEnum('consents');

  if (!(country in definition) || !definition[country].sectionsWithoutCheckbox?.length) return null;

  return (
    <div className={styles.consentNotices}>
      {definition[country].sectionsWithoutCheckbox.map(section => (
        <React.Fragment key={section.code}>
          {isNotEmpty(section.title) ? (
            <p className={styles.sectionTitle} dangerouslySetInnerHTML={{ __html: section.title }} />
          ) : null}
          {section.consents.map(it => (
            <React.Fragment key={it.code}>
              <span className={styles.item} dangerouslySetInnerHTML={{ __html: it.title }} />
              {isNotEmpty(it.description) ? (
                <p className={styles.description} dangerouslySetInnerHTML={{ __html: it.description }} />
              ) : null}
            </React.Fragment>
          ))}
          {isNotEmpty(section.note) ? (
            <p className={styles.note} dangerouslySetInnerHTML={{ __html: section.note }} />
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
}
