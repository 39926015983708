import qs from 'query-string';

import type { LatLon } from '../model/Coordinates';
import type { Location, LocationType } from '../model/Location';
import type { Place } from '../model/Place';

import type { Country } from './Country';
import type { LocationPredictionAdapterOptions, Prediction, PredictionService } from './LocationPrediction';

interface MapboxFeatureContext {
  id: string;
  text: string;
  short_code?: string;
}
interface MapboxFeature {
  id: string;
  type: string;
  place_type: string | string[];
  address?: string;
  text: string;
  place_name: string;
  matching_text?: string;
  matching_place_name?: string;
  geometry: {
    coordinates: [number, number];
  };
  context?: MapboxFeatureContext[];
  properties?: {
    address: string;
  };
}

enum MapboxPlaceType {
  ADDRESS = 'address',
  POI = 'poi',
  POSTCODE = 'postcode',
  PLACE = 'place',
  REGION = 'region',
  COUNTRY = 'country',
}

interface MapboxGeocodingResponse {
  type: 'FeatureCollection';
  features: MapboxFeature[];
}

interface Options {
  country: string;
  language: string;
  limit: number;
  types: string;
  proximity: string;
}

export class MapboxPredictionService implements PredictionService {
  constructor(private options: LocationPredictionAdapterOptions) {}

  public async query(search: string, abortSignal: AbortSignal): Promise<Prediction[]> {
    try {
      const options: Partial<Options> = {
        types: 'address,poi,place',
        limit: 5,
      };
      const response = await this.api<MapboxGeocodingResponse>(search, abortSignal, options);
      return response.features.map(it => new MapboxResult(it));
    } catch (error) {
      if (error.name === 'AbortError') return [];
      throw error;
    }
  }

  public async geocoding(location: LatLon, abortSignal: AbortSignal): Promise<Place> {
    try {
      const response = await this.api<MapboxGeocodingResponse>(`${location.lon},${location.lat}`, abortSignal);
      if (!response.features.length) return null;
      const result = new MapboxResult(response.features[0]);
      return await result.getPlace();
    } catch (error) {
      if (error.name === 'AbortError') return null;
      throw error;
    }
  }

  private async api<TResult>(
    search: string,
    abortSignal: AbortSignal,
    options: Partial<Options> = {},
  ): Promise<TResult> {
    const query = {
      language: this.options.language,
      country: this.options.country,
      ...options,
      access_token: process.env.GATSBY_MAPBOX_ACCESS_TOKEN,
    };
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?${qs.stringify(query)}`,
      { signal: abortSignal },
    );
    if (!response.ok) {
      const data = await response.json();
      return Promise.reject(data);
    }
    return (await response.json()) as TResult;
  }
}

class MapboxResult implements Prediction {
  public id: string;
  public name: string;
  private context: Partial<Record<MapboxPlaceType, MapboxFeatureContext>> = {};

  constructor(private result: MapboxFeature) {
    for (const context of result.context ?? []) {
      const [type] = context.id.split('.');
      this.context[type] = context;
    }
    this.id = this.result.id;
    this.name = this.format();
  }

  public async getPlace(): Promise<Place> {
    return {
      name: this.name,
      lat: this.result.geometry.coordinates[1],
      lon: this.result.geometry.coordinates[0],
    };
  }

  public getLocation<T extends LocationType>(type: T): Partial<Location<T>> {
    return {
      type,
      street: this.street(),
      city: this.city(),
      country_code: this.context[MapboxPlaceType.COUNTRY]?.short_code?.toUpperCase() as Country,
      zip: this.context[MapboxPlaceType.POSTCODE]?.text,
      lat: this.result.geometry.coordinates[1],
      lon: this.result.geometry.coordinates[0],
    };
  }

  private street(): string {
    if (MapboxPlaceType.ADDRESS in this.context) {
      return this.context[MapboxPlaceType.ADDRESS]?.text;
    }
    if (this.result.place_type.includes(MapboxPlaceType.ADDRESS)) {
      return [this.result.text, this.result.address].filter(Boolean).join(' ');
    }
    if (this.result.place_type.includes(MapboxPlaceType.POI)) {
      return [this.result.text, this.result.properties?.address].filter(Boolean).join(' ');
    }
    return this.result.text;
  }

  private city(): string {
    if (MapboxPlaceType.PLACE in this.context) {
      return this.context[MapboxPlaceType.PLACE]?.text;
    }
    if (MapboxPlaceType.REGION in this.context) {
      return this.context[MapboxPlaceType.REGION]?.text;
    }
    return null;
  }

  private format(): string {
    return [this.street(), this.city()].filter(Boolean).join(', ');
  }
}
