import React from 'react';
import { useDispatch } from 'react-redux';
import { boolean, object, string } from 'yup';

import { usePageView } from '../../hooks/useTracking';
import {
  closeUserRegistration,
  completeIDNumber,
  ID_NUMBER_LENGTH,
  type UserRegistrationSteps,
} from '../../modules/UserRegistration/SumsubUserRegistration';
import { useRouteContext } from '../../RouteProvider';
import { ApiError } from '../../services/Api';
import Tracking from '../../services/Tracking';
import { flashMessageError } from '../../store/session';
import Button from '../Button';
import { Checkbox } from '../Input/Checkbox';
import { TextInput } from '../Input/Input';
import { SubmitButton } from '../Input/SubmitButton';
import { useFormValidationSchema, useFormValues } from '../Input/useField';
import { useForm } from '../Input/useForm';
import type { SwitchChildrenProps } from '../Switch';
import T, { useTranslation } from '../Translate';
import WizardNavigation from '../WizardNavigation';

import styles from './UserRegistrationIDNumber.module.scss';

interface FormValues extends Record<string, unknown> {
  idNumber: string;
  skip: boolean;
}

export function UserRegistrationIDNumber(_: SwitchChildrenProps<UserRegistrationSteps>): JSX.Element {
  const dispatch: DispatchFunction = useDispatch();
  const { navigate, generatePath } = useRouteContext();
  const { t } = useTranslation();
  const validationSchema = useFormValidationSchema<FormValues>(
    object().shape({
      idNumber: string().when('skip', {
        is: false,
        then: schema =>
          schema.required('global.required').length(
            ID_NUMBER_LENGTH,
            t('global.validation.length', {
              data: { length: ID_NUMBER_LENGTH },
            }),
          ),
        otherwise: schema => schema.optional(),
      }),
      skip: boolean().default(false),
    }),
  );
  const formValues = useFormValues({ idNumber: '', skip: false }, validationSchema);
  const [handleSubmit, form] = useForm([formValues.idNumber, formValues.skip], async () => {
    try {
      Tracking.track('USER_REG_ID_NUMBER_COMPLETED');
      await dispatch(completeIDNumber(formValues.idNumber.value, formValues.skip.value));
    } catch (error) {
      if (error instanceof ApiError) {
        const message = error.getValidationMessage();
        if (typeof message === 'string') return formValues.setError('idNumber', message);
      }
      dispatch(flashMessageError(error.message));
    }
  });

  const handleClose = () => {
    const redirect = dispatch(closeUserRegistration());
    if (redirect) {
      navigate(generatePath(redirect));
    }
  };

  usePageView('signup_personal_number');

  return (
    <main className="main-container container">
      <WizardNavigation hideBackButton />
      <form className="container container--md" onSubmit={handleSubmit}>
        <T as="h1" className={styles.title} id="user_registration_birth_number_title" />
        <T as="p" className={styles.description} id="user_registration_birth_number_description" multiline />

        <div className={styles.form}>
          <TextInput
            autoComplete="off"
            disabled={formValues.skip.value}
            error={form.errors.idNumber}
            label={<T id="user_registration_birth_number_input_hint" />}
            name="idNumber"
            onChange={formValues.idNumber.setValue}
            pattern={`^[0-9]{0,${ID_NUMBER_LENGTH}}$`}
            tabIndex={0}
            type="text"
            value={formValues.idNumber.value}
          />

          <div>
            <hr className={styles.separator} />

            <Checkbox
              checked={formValues.skip.value}
              className="secondary"
              name="skip"
              onChange={formValues.skip.setValue}
              value=""
            >
              <T id="user_registration_birth_number_no_id_text" />
            </Checkbox>
          </div>
        </div>

        <SubmitButton block className="mt-5" primary submitting={form.submitting}>
          <T id="user_registration_birth_number_continue" />
        </SubmitButton>

        <Button block className="mt-4 text-muted" onClick={handleClose}>
          <T id="user_registration_birth_number_save_and_finish_later" />
        </Button>
      </form>
    </main>
  );
}
