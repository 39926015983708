import type { LatLon } from '../model/Coordinates';

let lastKnownLocation: LatLon;

export async function getDeviceLocation(): Promise<LatLon> {
  if (!navigator.geolocation) {
    return Promise.reject();
  }
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(position => {
      const location = {
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      };
      lastKnownLocation = location;
      resolve(location);
    }, reject);
  });
}

export function getLastKnownDeviceLocation(): LatLon {
  return lastKnownLocation;
}
