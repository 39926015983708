import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { useEnum } from '../../hooks/useEnum';
import { usePageView } from '../../hooks/useTracking';
import {
  closeUserRegistration,
  completePhone,
  type UserRegistrationSteps,
} from '../../modules/UserRegistration/SumsubUserRegistration';
import { useRouteContext } from '../../RouteProvider';
import { ApiError } from '../../services/Api';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { flashMessageError } from '../../store/session';
import Button from '../Button';
import { TextInput } from '../Input/Input';
import { SelectWithFilter } from '../Input/Select';
import { SubmitButton } from '../Input/SubmitButton';
import { useField, useValidationSchema } from '../Input/useField';
import { useForm } from '../Input/useForm';
import type { SwitchChildrenProps } from '../Switch';
import T from '../Translate';
import WizardNavigation from '../WizardNavigation';

import styles from './UserRegistrationPhone.module.scss';

export default function UserRegistrationPhone(_: SwitchChildrenProps<UserRegistrationSteps>) {
  const dispatch: DispatchFunction = useDispatch();
  const [countries] = useEnum('country');
  const { navigate, generatePath } = useRouteContext();
  const initialValues = useSelector((store: ReduxState) => {
    const initialValues = {
      dialCode:
        countries.find(c => c.value.toLowerCase() === store.userRegistration.country.toLowerCase())?.calling_code ??
        countries[0].calling_code,
      phone: '',
    };
    if (store.userRegistration.phone) {
      [initialValues.dialCode, initialValues.phone] = store.userRegistration.phone;
      return initialValues;
    }
    if (store.user.data?.phone) {
      const dialCode = countries.find(code => store.user.data.phone.startsWith(code.calling_code));
      if (dialCode) {
        initialValues.dialCode = dialCode.calling_code;
        initialValues.phone = store.user.data.phone.replace(dialCode.calling_code, '');
      }
    }
    return initialValues;
  });

  usePageView('signup_phone_form');

  const dialCodeValidation = useValidationSchema(yup.string().oneOf(countries.map(dc => dc.calling_code)));
  const [dialCode, setDialCode] = useField<string>('dialCode', initialValues.dialCode, dialCodeValidation);

  const phoneValidation = useValidationSchema(yup.string().required('global.required').min(9, 'global.phone.invalid'));
  const [phone, setPhone, setPhoneError] = useField<string>('phone', initialValues.phone, phoneValidation);

  const [handleSubmit, form] = useForm([dialCode], async () => {
    try {
      Tracking.track('USER_REG_PHONE_VERIFICATION_COMPLETED');
      await dispatch(completePhone(dialCode.value, phone.value.trim()));
    } catch (error) {
      if (error instanceof ApiError) {
        setPhoneError('user.registration.phone.invalid');
        return;
      }
      dispatch(flashMessageError(error.message));
    }
  });

  const handleClose = () => {
    const redirect = dispatch(closeUserRegistration());
    if (redirect) {
      navigate(generatePath(redirect));
    }
  };

  return (
    <main className="main-container container">
      <WizardNavigation />
      <form className="container container--md" onSubmit={handleSubmit}>
        <T as="h1" className={styles.title} id="phone_verification_v2_title" />
        <T as="p" className={styles.description} id="phone_verification_v2_description" multiline />

        <div className={styles.form}>
          <SelectWithFilter
            className={styles.dialCode}
            label={<T id="global.country" />}
            onChange={setDialCode}
            options={countries.map(it => ({
              key: it.value,
              value: it.calling_code,
              filterValue: it.calling_code,
              label: (
                <>
                  <i className={`icon-left flag flag-${it.value.toLowerCase()}`} />
                  {it.calling_code}
                </>
              ),
            }))}
            value={dialCode.value}
          >
            {(it, input) => (
              <>
                <i className={`icon-left flag flag-${it.key.toLowerCase()}`} />
                {input}
              </>
            )}
          </SelectWithFilter>

          <TextInput
            autoComplete="tel-national"
            className={styles.phone}
            error={form.errors.phone}
            label={<T id="user.registration.phone.phone.label" />}
            name="phone"
            onChange={setPhone}
            required
            tabIndex={0}
            type="tel"
            value={phone.value}
          />
        </div>

        <SubmitButton block className="mt-5" primary submitting={form.submitting}>
          <T id="user.registration.phone.continue" />
        </SubmitButton>

        <Button block className="mt-4 text-muted" onClick={handleClose}>
          <T id="user_registration_phone_save_and_finish_later" />
        </Button>
      </form>
    </main>
  );
}
