import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageView } from '../../hooks/useTracking';
import { requestEmailVerification } from '../../model/UserVerification';
import {
  changeEmailInRegistration,
  closeUserRegistration,
  type UserRegistrationSteps,
} from '../../modules/UserRegistration/SumsubUserRegistration';
import { useRouteContext } from '../../RouteProvider';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { flashMessageError, flashMessageSuccess } from '../../store/session';
import Button from '../Button';
import type { SwitchChildrenProps } from '../Switch';
import T from '../Translate';
import WizardNavigation from '../WizardNavigation';

import styles from './UserRegistrationEmailVerification.module.scss';

interface Props {
  hideContinueButton?: boolean;
  pageviewProps?: string;
}

export default function UserRegistrationEmailVerification(props: Props & SwitchChildrenProps<UserRegistrationSteps>) {
  const dispatch: DispatchFunction = useDispatch();
  const { navigate, generatePath } = useRouteContext();
  const state = useSelector((state: ReduxState) => ({
    email: state.userRegistration.email ?? state.user?.email,
  }));

  usePageView('signup_emailverification', props.pageviewProps);

  const handleResend = async () => {
    try {
      await requestEmailVerification();
      dispatch(flashMessageSuccess('global.verificationEmailResent'));
      Tracking.track('USER_REG_EMAIL_VERIFICATION_RESENT');
    } catch (error) {
      dispatch(flashMessageError(error.message));
    }
  };

  const handleClose = () => {
    const redirect = dispatch(closeUserRegistration());
    if (redirect) {
      navigate(generatePath(redirect));
    }
  };

  const handleEditEmail = () => {
    dispatch(changeEmailInRegistration());
    Tracking.track('USER_REG_EMAIL_VERIFICATION_EDIT');
  };

  return (
    <main className="main-container container">
      <WizardNavigation />

      <div className="container container--md">
        <T as="h1" className={styles.title} id="email_verification_v2_title" />
        <T as="p" className={styles.description} id="email_verification_v2_description" multiline />

        <p className={styles.email}>{state.email}</p>
        <div className={styles.editEmail}>
          <T as="p" id="email_verification_v2_wrong_email" />
          <Button onClick={handleEditEmail} variant="link">
            <T id="email_verification_v2_edit" />
          </Button>
        </div>

        <div className={styles.resendContainer}>
          <T as="span" className={styles.textGrey} id="email_verification_v2_check_spam" />
          <Button className="button-inline" onClick={handleResend} variant="link">
            <T id="user.registration.emailVerification.resend" />
          </Button>
        </div>

        <Button block className="text-muted mt-5" onClick={handleClose}>
          <T id="user_registration_email_save_and_finish_later" />
        </Button>
      </div>
    </main>
  );
}
