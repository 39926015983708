import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SumsubWebSdk from '@sumsub/websdk-react';

import {
  completeSumsub,
  completeSumsubIntro,
  type UserRegistrationSteps,
} from '../../modules/UserRegistration/SumsubUserRegistration';
import { Logger } from '../../services/Logger';
import { fetchSumsubToken } from '../../services/SumSub';
import type { ReduxState } from '../../store';
import Loader from '../Loader';
import type { SwitchChildrenProps } from '../Switch';

export function UserRegistrationSumsub(_: SwitchChildrenProps<UserRegistrationSteps>) {
  const logger = useRef(
    new Logger('UserRegistrationSumsub', {
      [Logger.Level.DEBUG]: process.env.NODE_ENV !== 'production',
    }),
  );
  const dispatch: DispatchFunction = useDispatch();
  const state = useSelector((store: ReduxState) => ({
    sumsub_token: store.userRegistration.sumsub_token,
    language: store.app.language,
  }));

  const handleTokenExpiration = async () => {
    return await fetchSumsubToken();
  };

  const handleEvent = (type, payload) => {
    logger.current.debug(type, payload);
    if (type === 'idCheck.applicantReviewComplete') {
      dispatch(completeSumsub());
    }
  };

  useEffect(() => {
    if (!state.sumsub_token) {
      dispatch(completeSumsubIntro());
    }
  }, []);

  return (
    <main className="main-container container">
      {state.sumsub_token ? (
        <SumsubWebSdk
          accessToken={state.sumsub_token}
          config={{ version: 2, lang: state.language }}
          expirationHandler={handleTokenExpiration}
          onMessage={handleEvent}
        />
      ) : (
        <Loader />
      )}
    </main>
  );
}
