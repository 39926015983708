import React from 'react';
import { useRef } from 'react';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';

import type { ValueLabelPair } from '../../model/Enum';

import { Radio } from './Radio';

import styles from './RadioGroup.module.scss';

interface RadioGroupProps<T extends string = string> {
  value: T;
  options: ValueLabelPair<T>[];
  error?: boolean;
  name?: string;
  className?: string;

  onChange(value: T): void;

  formatLabel?(option: ValueLabelPair<T>): React.ReactNode;
}

export function RadioGroup<T extends string = string>(props: RadioGroupProps<T>) {
  const name = useRef(props.name ?? uuid());
  return (
    <div className={cn(styles.radioGroup, props.className)}>
      {props.options.map(option => (
        <Radio
          checked={props.value === option.value}
          error={props.error}
          key={option.value}
          name={name.current}
          onChange={props.onChange}
          value={option.value}
        >
          {props.formatLabel ? props.formatLabel(option) : option.label}
        </Radio>
      ))}
    </div>
  );
}
