import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { string } from 'yup';

import { getSearchQueryParameter } from '../../helpers';
import { INVITATION_CODE } from '../../model/User';
import { completeEmail, type UserRegistrationSteps } from '../../modules/UserRegistration/SumsubUserRegistration';
import RouteEnum from '../../RouteEnum';
import { useRouteContext } from '../../RouteProvider';
import { TextInput } from '../Input/Input';
import { isPasswordStrong, PasswordInput } from '../Input/PasswordInput';
import { SubmitButton } from '../Input/SubmitButton';
import { useField, useValidationSchema } from '../Input/useField';
import { useForm } from '../Input/useForm';
import Link from '../Link';
import type { SwitchChildrenProps } from '../Switch';
import T from '../Translate';
import WizardNavigation from '../WizardNavigation';

import { UserRegistrationExistingUserModal } from './UserRegistrationExistingUserModal';

import styles from './UserRegistrationIntro.module.scss';

export default function UserRegistrationIntro(_: SwitchChildrenProps<UserRegistrationSteps>) {
  const dispatch: DispatchFunction = useDispatch();
  const { location } = useRouteContext();
  const [opened, setOpened] = useState(false);
  const emailValidation = useValidationSchema(string().email('global.email.invalid').required('global.required'));
  const [email, setEmail] = useField<string>('email', '', emailValidation);
  const [password, setPassword] = useField<string>('password', '', value => {
    if (!value || value === '') return <T id="global.required" />;
    if (!isPasswordStrong(value)) return <T id="global.password.invalid" />;
    return null;
  });

  const [handleSubmit, form] = useForm([email, password], async () => {
    const invitationCode = getSearchQueryParameter(location.search, INVITATION_CODE) as string | null;
    const guard = await dispatch(completeEmail(email.value, password.value, invitationCode));
    if (guard) {
      setOpened(true);
    }
  });

  return (
    <main className="main-container container">
      <WizardNavigation />
      <form className="container container--md" onSubmit={handleSubmit}>
        <T as="h2" className={styles.title} id="signup_v2_title" />

        <TextInput
          autoComplete="email"
          error={form.errors.email}
          id="sign-up-email"
          label={<T id="signup_v2_email_label" />}
          name="email"
          onChange={setEmail}
          required
          tabIndex={1}
          type="email"
          value={email.value}
        />

        <PasswordInput
          autoComplete="new-password"
          error={form.errors.password}
          highlightHints={password.touched || form.submitted}
          id="sign-up-password"
          label={<T id="signup_v2_password_label" />}
          name="password"
          onChange={setPassword}
          required
          tabIndex={1}
          type="password"
          value={password.value}
        />

        <SubmitButton block className="mt-4" primary submitting={form.submitting}>
          <T id="modals.signUp.submit" />
          <i className="icon icon--arrow-right-circle" />
        </SubmitButton>

        <div className={styles.bottom}>
          <T as="span" id="signup_already_have_account" />
          <Link to={RouteEnum.SIGN_IN}>
            <T id="signup_login" />
          </Link>
        </div>
      </form>
      {opened ? <UserRegistrationExistingUserModal email={email.value} onClose={() => setOpened(false)} /> : null}
    </main>
  );
}
