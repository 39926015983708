import React from 'react';
import { useDispatch } from 'react-redux';

import { usePageView } from '../../hooks/useTracking';
import { completeSumsubIntro, type UserRegistrationSteps } from '../../modules/UserRegistration/SumsubUserRegistration';
import Button from '../Button';
import { SkodaXLogo } from '../Icons/SkodaXLogo';
import { StaticImage } from '../Image';
import type { SwitchChildrenProps } from '../Switch';
import T, { TProvider } from '../Translate';
import WizardNavigation from '../WizardNavigation';

import styles from './UserRegistrationSumsubIntro.module.scss';

export function UserRegistrationSumsubIntro(_: SwitchChildrenProps<UserRegistrationSteps>) {
  const dispatch: DispatchFunction = useDispatch();

  usePageView('signup_identity_intro');

  const handleContinue = () => {
    dispatch(completeSumsubIntro());
  };

  return (
    <main className="main-container container">
      <WizardNavigation hideBackButton />

      <TProvider prefix="user_registration_sumsub_intro" prefixSeparator="_">
        <div className="container container--md">
          <T as="h1" className="h4" id="title" />
          <T as="p" className="sm" id="description" />

          <div className="flex column center-x">
            <StaticImage className="mb-5" name="drivers-licence" />

            <T as="p" className="sm text-grey-3" id="our_partners" />

            <div className={styles.partners}>
              <SkodaXLogo />
              <StaticImage name="logo_uniqa_black_oneline" />
            </div>
          </div>

          <Button block className="mt-5 text-center" onClick={handleContinue} primary>
            <T id="button_continue" />
          </Button>
        </div>
      </TProvider>
    </main>
  );
}
