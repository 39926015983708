import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'yup';

import { completeEmailChange, type UserRegistrationSteps } from '../../modules/UserRegistration/SumsubUserRegistration';
import type { ReduxState } from '../../store';
import { TextInput } from '../Input/Input';
import { SubmitButton } from '../Input/SubmitButton';
import { useField, useValidationSchema } from '../Input/useField';
import { useForm } from '../Input/useForm';
import type { SwitchChildrenProps } from '../Switch';
import T from '../Translate';
import WizardNavigation from '../WizardNavigation';

import { UserRegistrationExistingUserModal } from './UserRegistrationExistingUserModal';

export default function UserRegistrationEmailChange(_: SwitchChildrenProps<UserRegistrationSteps>) {
  const dispatch: DispatchFunction = useDispatch();
  const state = useSelector((state: ReduxState) => state.userRegistration);
  const [opened, setOpened] = useState(false);
  const emailValidation = useValidationSchema(string().email('global.email.invalid').required('global.required'));
  const [email, setEmail] = useField<string>('email', state.email, emailValidation);

  const [handleSubmit, form] = useForm([email], async () => {
    const guard = await dispatch(completeEmailChange(email.value));
    if (guard) {
      setOpened(true);
    }
  });

  return (
    <main className="main-container container">
      <WizardNavigation />
      <form className="container container--md" onSubmit={handleSubmit}>
        <T as="h1" className="h4" id="user_registration_email_title" />
        <TextInput
          autoComplete="email"
          error={form.errors.email}
          id="sign-up-email"
          label="user_registration_email_edit_label"
          name="email"
          onChange={setEmail}
          tabIndex={1}
          type="email"
          value={email.value}
        />
        <SubmitButton block className="mt-4" primary submitting={form.submitting}>
          <T id="user_registration_email_button_continue" />
        </SubmitButton>
      </form>
      {opened ? <UserRegistrationExistingUserModal email={email.value} onClose={() => setOpened(false)} /> : null}
    </main>
  );
}
