import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';

import { usePageView } from '../../hooks/useTracking';
import type { ValueLabelPair } from '../../model/Enum';
import { UserRole } from '../../model/UserRole';
import {
  closeUserRegistration,
  completeRole,
  type UserRegistrationSteps,
} from '../../modules/UserRegistration/SumsubUserRegistration';
import { useRouteContext } from '../../RouteProvider';
import Tracking from '../../services/Tracking';
import { flashMessageError } from '../../store/session';
import Button from '../Button';
import FormikSubmitButton from '../Form/SubmitButton';
import { RadioGroup } from '../Input/RadioGroup';
import type { SwitchChildrenProps } from '../Switch';
import T, { useTranslation } from '../Translate';
import WizardNavigation from '../WizardNavigation';

export default function UserRegistrationRole(_: SwitchChildrenProps<UserRegistrationSteps>) {
  const dispatch: DispatchFunction = useDispatch();
  const { navigate, generatePath } = useRouteContext();
  const { t } = useTranslation();
  const options: ValueLabelPair<UserRole>[] = Object.values(UserRole).map(role => ({
    value: role,
    label: t(`user.registration.role.${role}`),
  }));

  const handleClose = () => {
    const redirect = dispatch(closeUserRegistration());
    if (redirect) {
      navigate(generatePath(redirect));
    }
  };

  const formik = useFormik({
    validationSchema: object({
      role: string().optional().oneOf(Object.values(UserRole)).required('global.required'),
    }),
    initialValues: {
      role: null,
    },
    async onSubmit({ role }) {
      try {
        await dispatch(completeRole(role));
        Tracking.track('USER_REG_ROLE_COMPLETED', role);
      } catch (error) {
        dispatch(flashMessageError(error.message));
      }
    },
  });

  usePageView('signup_user_role');

  return (
    <main className="main-container container">
      <WizardNavigation hideBackButton />
      <FormikProvider value={formik}>
        <Form className="container container--md">
          <T as="h1" className="h4" id="user.registration.role.title" />
          <T as="p" className="sm mb-4" id="user.registration.role.description" />

          <RadioGroup
            error={!!formik.errors.role}
            onChange={value => formik.setFieldValue('role', value, true)}
            options={options}
            value={formik.values.role}
          />

          <FormikSubmitButton className="button button-primary button-lg button-block button-icon mt-5 d-flex justify-content-center">
            <T id="user.registration.role.submit" />
            <i className="icon icon-arrow-right-circle" />
          </FormikSubmitButton>

          <Button className="button-block mt-4 text-muted" onClick={handleClose}>
            <T id="user_registration_role_save_and_finish_later" />
          </Button>
        </Form>
      </FormikProvider>
    </main>
  );
}
