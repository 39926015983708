import React from 'react';
import { useSelector } from 'react-redux';

import { usePageView } from '../../hooks/useTracking';
import { UserState } from '../../model/User';
import type { UserRegistrationSteps } from '../../modules/UserRegistration/SumsubUserRegistration';
import RouteEnum from '../../RouteEnum';
import type { ReduxState } from '../../store';
import { StaticImage } from '../Image';
import Link from '../Link';
import Switch, { type SwitchChildrenProps } from '../Switch';
import T, { TProvider } from '../Translate';
import WizardNavigation from '../WizardNavigation';

export default function UserRegistrationComplete(_: SwitchChildrenProps<UserRegistrationSteps>) {
  usePageView('signup_victory');
  const user = useSelector((store: ReduxState) => store.user);

  return (
    <main className="main-container container">
      <WizardNavigation hideBackButton />

      <TProvider prefix="user_registration_summary" prefixSeparator="_">
        <Switch value={user?.state}>
          <Verified case={UserState.ACTIVE} />
          <Declined case={[UserState.DECLINED, UserState.BANNED, UserState.DELETED, UserState.INACTIVE]} />
          <Pending case={UserState.WAITING_FOR_REVISION_AUTOMATED} />
          <PendingLong case={UserState.WAITING_FOR_REVISION_MANUAL} />
        </Switch>
      </TProvider>
    </main>
  );
}

function Verified(_: SwitchChildrenProps<UserState>) {
  usePageView('signup_verification_result', 'Approved');

  return (
    <TProvider prefix="success">
      <div className="container container--md">
        <T as="h1" className="h4" id="title" />
        <T as="p" className="sm" id="description" multiline />

        <StaticImage className="flex column center-x" name="verified" />

        <Link className="button button-primary button-block button-icon mt-5" replace to={RouteEnum.RENT_CAR}>
          <T id="button" />
          <i className="icon icon-arrow-right-circle" />
        </Link>
      </div>
    </TProvider>
  );
}

function Declined(_: SwitchChildrenProps<UserState>) {
  usePageView('signup_verification_result', 'Declined');

  return (
    <TProvider prefix="failed">
      <div className="container container--md">
        <T as="h1" className="h4" id="title" />
        <T as="p" className="sm" id="description" multiline />

        <StaticImage className="flex column center-x" name="declined" />

        <Link className="button button-primary button-block button-icon mt-5" replace to={RouteEnum.HOMEPAGE}>
          <T id="button" />
          <i className="icon icon-arrow-right-circle" />
        </Link>
      </div>
    </TProvider>
  );
}

function Pending(_: SwitchChildrenProps<UserState>) {
  usePageView('signup_verification_pending');

  return (
    <TProvider prefix="pending">
      <div className="container container--md">
        <T as="h1" className="h4" id="title" />
        <T as="p" className="sm" id="description" multiline />

        <StaticImage className="flex column center-x" name="hourglass 1" />

        <Link className="button button-primary button-block button-icon mt-5" replace to={RouteEnum.HOMEPAGE}>
          <T id="button" />
          <i className="icon icon-arrow-right-circle" />
        </Link>
      </div>
    </TProvider>
  );
}

function PendingLong(_: SwitchChildrenProps<UserState>) {
  usePageView('signup_verification_pending');

  return (
    <TProvider prefix="pending_long">
      <div className="container container--md">
        <T as="h1" className="h4" id="title" />
        <T as="p" className="sm" id="description" multiline />

        <StaticImage className="flex column center-x" name="hourglass 1" />

        <Link className="button button-primary button-block button-icon mt-5" replace to={RouteEnum.HOMEPAGE}>
          <T id="button" />
          <i className="icon icon-arrow-right-circle" />
        </Link>
      </div>
    </TProvider>
  );
}
