import type { LatLon } from '../model/Coordinates';
import type { Location, LocationType } from '../model/Location';
import type { Place } from '../model/Place';

import type { Country } from './Country';
import { getDeviceLocation } from './DeviceLocation';
import type { Language } from './Language';
import { MapboxPredictionService } from './Mapbox';

export interface Prediction {
  id: string;
  name: string;
  icon?: string;
  translate?: boolean;

  getPlace(): Promise<Place>;
  getLocation?<T extends LocationType>(type: T): Partial<Location<T>>;
}

type LocationPredictionHandler = (predictions: Prediction[]) => void;

export interface LocationPredictionAdapterOptions {
  language: Language;
  country?: Country | string;
}

export interface PredictionService {
  query(search: string, abortSignal: AbortSignal): Promise<Prediction[]>;
  geocoding(location: LatLon, abortSignal: AbortSignal): Promise<Place>;
}

export class LocationPrediction {
  private predictionService: MapboxPredictionService;
  private signal = new AbortController();
  private handler: LocationPredictionHandler = null;
  private running = false;

  public readonly geolocationPrediction: Prediction = {
    id: 'geolocation',
    icon: 'location',
    name: 'global.currentLocation',
    translate: true,
    getPlace: async () => {
      const location = await getDeviceLocation();
      return this.predictionService.geocoding(location, this.signal.signal);
    },
  };

  constructor(public options: LocationPredictionAdapterOptions) {
    this.predictionService = new MapboxPredictionService(options);
  }

  public setOptions(options: LocationPredictionAdapterOptions): void {
    this.options = options;
    this.predictionService = new MapboxPredictionService(options);
  }

  public async query(query: string): Promise<void> {
    if (!this.handler || !query || !query.length) return;
    if (this.running) {
      this.signal.abort();
      this.signal = new AbortController();
    }
    this.running = true;
    const response = await this.predictionService.query(query, this.signal.signal);
    this.running = false;
    this.handler?.(response);
  }

  public onPrediction(handler: LocationPredictionHandler): () => void {
    this.handler = handler;
    return () => {
      this.handler = null;
    };
  }
}
