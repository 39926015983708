import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  closeUserRegistration,
  returnToPreviousUserRegistrationStep,
  UserRegistrationSteps,
} from '../../modules/UserRegistration/SumsubUserRegistration';
import { useRouteContext } from '../../RouteProvider';
import { AppState } from '../../services/AppState';
import type { ReduxState } from '../../store';
import PageLayout from '../Layout/Page';
import WizardNavbar from '../Layout/WizardNavbar';
import MetaTags from '../MetaTags';
import Switch from '../Switch';
import { WizardNavigationProvider } from '../WizardNavigation';

import UserRegistrationAddress from './UserRegistrationAddress';
import UserRegistrationComplete from './UserRegistrationComplete';
import UserRegistrationCountry from './UserRegistrationCountry';
import UserRegistrationEmailChange from './UserRegistrationEmailChange';
import UserRegistrationEmailVerification from './UserRegistrationEmailVerification';
import { UserRegistrationIDNumber } from './UserRegistrationIDNumber';
import UserRegistrationIntro from './UserRegistrationIntro';
import UserRegistrationPhone from './UserRegistrationPhone';
import UserRegistrationPhoneVerification from './UserRegistrationPhoneVerification';
import UserRegistrationRole from './UserRegistrationRole';
import { UserRegistrationSumsub } from './UserRegistrationSumsub';
import { UserRegistrationSumsubIntro } from './UserRegistrationSumsubIntro';

export default function UserRegistration() {
  const dispatch: DispatchFunction = useDispatch();
  const { navigate, generatePath } = useRouteContext();
  const state = useSelector((store: ReduxState) => store.userRegistration);

  const handleClose = () => {
    const redirect = dispatch(closeUserRegistration());
    navigate(generatePath(redirect));
  };

  const handleReturn = async () => {
    const [redirect, callback] = dispatch(returnToPreviousUserRegistrationStep());
    if (redirect) {
      await navigate(generatePath(redirect));
    }
    callback?.();
  };

  return (
    <PageLayout customNavbar={<WizardNavbar onClose={handleClose} />} hideFooter requiredState={AppState.LOADED}>
      <MetaTags title="user.registration.meta.title" />
      <WizardNavigationProvider
        onReturnClick={handleReturn}
        supportModal={{
          noOfFaqs: 12,
          pageview: 'signup_support',
        }}
        translationPrefix="user.registration"
      >
        <Switch value={state.step}>
          <UserRegistrationIntro case={UserRegistrationSteps.EMAIL} />
          <UserRegistrationCountry case={UserRegistrationSteps.COUNTRY} />
          <UserRegistrationRole case={UserRegistrationSteps.ROLE} />
          <UserRegistrationEmailVerification case={UserRegistrationSteps.EMAIL_VERIFICATION} />
          <UserRegistrationEmailChange case={UserRegistrationSteps.EMAIL_CHANGE} />
          <UserRegistrationPhone case={UserRegistrationSteps.PHONE} />
          <UserRegistrationPhoneVerification case={UserRegistrationSteps.PHONE_VERIFICATION} />
          <UserRegistrationIDNumber case={UserRegistrationSteps.ID_NUMBER} />
          <UserRegistrationAddress case={UserRegistrationSteps.ADDRESS} />
          <UserRegistrationSumsubIntro case={UserRegistrationSteps.SUMSUB_INTRO} />
          <UserRegistrationSumsub case={UserRegistrationSteps.SUMSUB} />
          <UserRegistrationComplete />
        </Switch>
      </WizardNavigationProvider>
    </PageLayout>
  );
}
