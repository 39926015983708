import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'yup';

import { useEnum } from '../../hooks/useEnum';
import { usePageView } from '../../hooks/useTracking';
import { LocationType } from '../../model/Location';
import {
  closeUserRegistration,
  completeAddress,
  type UserRegistrationSteps,
} from '../../modules/UserRegistration/SumsubUserRegistration';
import { useRouteContext } from '../../RouteProvider';
import type { ReduxState } from '../../store';
import { flashMessageError } from '../../store/session';
import Button from '../Button';
import { TextInput } from '../Input/Input';
import { PredictionInput } from '../Input/PredictionInput';
import { SelectWithFilter } from '../Input/Select';
import { SubmitButton } from '../Input/SubmitButton';
import { useValidatedField } from '../Input/useField';
import { useForm } from '../Input/useForm';
import type { SwitchChildrenProps } from '../Switch';
import T from '../Translate';
import WizardNavigation from '../WizardNavigation';

export default function UserRegistrationAddress(_: SwitchChildrenProps<UserRegistrationSteps>) {
  const dispatch: DispatchFunction = useDispatch();
  const { navigate, generatePath } = useRouteContext();
  const { initialCountry } = useSelector((store: ReduxState) => ({
    initialCountry: store.userRegistration.country,
  }));
  const [countries] = useEnum('country');
  const [street, setStreet] = useValidatedField('street', '', string().required('global.required'));
  const [city, setCity] = useValidatedField('city', '', string().required('global.required'));
  const [zip, setZip] = useValidatedField('zip', '', string().required('global.required'));
  const [country, setCountry] = useValidatedField(
    'country',
    initialCountry,
    string()
      .required('global.required')
      .oneOf(countries.map(c => c.value)),
  );

  const [handleSubmit, form] = useForm([street, city, zip, country], async () => {
    try {
      await dispatch(
        completeAddress({
          type: LocationType.HOME,
          street: street.value,
          city: city.value,
          zip: zip.value,
          country_code: country.value,
        }),
      );
    } catch (error) {
      dispatch(flashMessageError(error.message));
    }
  });

  const handleClose = () => {
    const redirect = dispatch(closeUserRegistration());
    if (redirect) {
      navigate(generatePath(redirect));
    }
  };

  usePageView('signup_basicinfo_address');

  return (
    <main className="main-container container">
      <WizardNavigation hideBackButton />

      <form className="container container--sm" onSubmit={handleSubmit}>
        <T as="h1" className="h4 mb-2" id="user.registration.basicInfo.address.title" />
        <T as="p" className="sm mb-4" id="user.registration.basicInfo.address.description" />

        <div className="row">
          <div className="col-12">
            <PredictionInput
              autoComplete="street-address"
              error={form.errors.street}
              label="user_address_hint_street_house_number"
              name="street"
              onChange={setStreet}
              onPredictionClick={prediction => {
                const location = prediction.getLocation(LocationType.HOME);
                setStreet(location.street);
                setCity(location.city);
                setZip(location.zip);
                if (location.country_code && countries.some(it => it.value === location.country_code)) {
                  setCountry(location.country_code);
                }
              }}
              type="text"
              value={street.value}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-8">
            <TextInput
              autoComplete="address-level2"
              error={form.errors.city}
              label="user.registration.basicInfo.address.city.label"
              name="city"
              onChange={setCity}
              type="text"
              value={city.value}
            />
          </div>
          <div className="col-12 col-md-4">
            <TextInput
              autoComplete="postal-code"
              error={form.errors.zip}
              label="user.registration.basicInfo.address.postalCode.label"
              name="zip"
              onChange={setZip}
              type="text"
              value={zip.value}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <SelectWithFilter
              error={form.errors.country}
              label="user.registration.basicInfo.address.country.label"
              onChange={setCountry}
              options={countries.map(c => ({
                value: c.value,
                filterValue: c.label,
                label: (
                  <>
                    <span className={`icon-left flag flag-${c.value.toLowerCase()}`} />
                    {c.label}
                  </>
                ),
              }))}
              value={country.value}
            >
              {(option, input) => (
                <>
                  <span className={`icon-left flag flag-${option.value.toLowerCase()}`} />
                  {input}
                </>
              )}
            </SelectWithFilter>
          </div>
        </div>

        <SubmitButton block className="mt-5" primary submitting={form.submitting}>
          <T id="user.registration.basicInfo.address.continue" />
        </SubmitButton>

        <Button block className="mt-4 text-muted" onClick={handleClose}>
          <T id="user_registration_address_save_and_finish_later" />
        </Button>
      </form>
    </main>
  );
}
