import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'yup';

import { usePageView } from '../../hooks/useTracking';
import { requestPhoneVerification } from '../../model/UserVerification';
import {
  closeUserRegistration,
  completePhoneVerification,
  returnToPreviousUserRegistrationStep,
  type UserRegistrationSteps,
} from '../../modules/UserRegistration/SumsubUserRegistration';
import { useRouteContext } from '../../RouteProvider';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { flashMessageError, flashMessageSuccess } from '../../store/session';
import Button from '../Button';
import { SubmitButton } from '../Input/SubmitButton';
import { useField, useValidationSchema } from '../Input/useField';
import { useForm } from '../Input/useForm';
import { VerificationCodeInput } from '../Input/VerificationCodeInput';
import type { SwitchChildrenProps } from '../Switch';
import T from '../Translate';
import WizardNavigation from '../WizardNavigation';

import styles from './UserRegistrationPhoneVerification.module.scss';

export default function UserRegistrationPhoneVerification(_: SwitchChildrenProps<UserRegistrationSteps>) {
  const dispatch: DispatchFunction = useDispatch();
  const { navigate, generatePath } = useRouteContext();

  const state = useSelector((store: ReduxState) => ({
    phone: store.userRegistration.phone.join(''),
  }));
  const codeValidation = useValidationSchema(
    string()
      .matches(/^\d{4}$/, 'user.registration.phoneVerification.invalidCode')
      .required('global.required'),
  );
  const [code, setCode, setCodeError] = useField<string>('code', '', codeValidation);
  const [handleSubmit, form] = useForm([code], async () => {
    try {
      await dispatch(completePhoneVerification(code.value));
      Tracking.track('USER_REG_PHONE_VERIFICATION_SUBMITTED', true);
    } catch (error) {
      Tracking.track('USER_REG_PHONE_VERIFICATION_SUBMITTED', false);
      setCodeError('user.registration.phoneVerification.invalidCode');
    }
  });

  const handleEditPhone = () => {
    dispatch(returnToPreviousUserRegistrationStep());
    Tracking.track('USER_REG_PHONE_VERIFICATION_EDIT');
  };

  const handleClose = () => {
    const redirect = dispatch(closeUserRegistration());
    if (redirect) {
      navigate(generatePath(redirect));
    }
  };

  usePageView('signup_phone_verification');

  return (
    <main className="main-container container">
      <WizardNavigation />

      <form className="container container--md" onSubmit={handleSubmit}>
        <T as="h1" className={styles.title} id="phone_confirm_v2_title" />
        <T as="p" className={styles.description} id="phone_confirm_v2_description" />

        <p className={styles.email}>{state.phone}</p>
        <div className={styles.editEmail}>
          <T as="p" id="phone_confirm_v2_wrong_phone" />
          <Button onClick={handleEditPhone} variant="link">
            <T id="phone_confirm_v2_wrong_phone_edit" />
          </Button>
        </div>

        <div className={styles.container}>
          <VerificationCodeInput
            autoFocus
            className={styles.input}
            clearOnError
            error={form.errors.code}
            length={4}
            onChange={setCode}
            value={code.value}
          />

          <ResendButton />
        </div>

        <SubmitButton block className={styles.button} primary submitting={form.submitting}>
          <T id="user.registration.phoneVerification.continue" />
        </SubmitButton>

        <Button block className="mt-4 text-muted" onClick={handleClose}>
          <T id="user_registration_phone_save_and_finish_later" />
        </Button>
      </form>
    </main>
  );
}

const MAY_RESEND_AFTER_SECS = 30;

function ResendButton() {
  const dispatch = useDispatch();
  const interval = useRef<UnsafeAny>(null);
  const start = useRef<number>(Date.now());
  const [state, setState] = useState({
    remaining: MAY_RESEND_AFTER_SECS,
  });

  const createInterval = () => {
    interval.current = setInterval(() => {
      const remaining = MAY_RESEND_AFTER_SECS - Math.floor((Date.now() - start.current) / 1000);
      if (remaining <= 0) {
        clearInterval(interval.current);
        interval.current = null;
      }
      setState({ remaining });
    }, 1000);
  };

  const handleResend = async () => {
    try {
      Tracking.track('USER_REG_PHONE_VERIFICATION_RESENT');
      await requestPhoneVerification();
      start.current = Date.now();
      createInterval();
      dispatch(flashMessageSuccess('user.registration.phoneVerification.messageSent'));
    } catch (error) {
      dispatch(flashMessageError(error.message));
    }
  };

  useEffect(() => {
    if (!interval.current) {
      createInterval();
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []);

  if (state.remaining > 0) {
    return (
      <T as="span" className={styles.resendIn} data={[state.remaining]} html id="phone_confirm_v2_resend_text_sec" />
    );
  }

  return (
    <Button onClick={handleResend} variant="link">
      <T id="user.registration.phoneVerification.resend" />
    </Button>
  );
}
