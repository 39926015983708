import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import type { Country } from '../../services/Country';
import { LocationPrediction, type Prediction } from '../../services/LocationPrediction';
import type { ReduxState } from '../../store';
import Loader from '../Loader';

import { type InputProps, TextInput } from './Input';

import styles from './PredictionInput.module.scss';

interface PredictionInputProps extends InputProps {
  country?: Country | string;
  onPredictionClick(prediction: Prediction): void;
}

interface State {
  value: string;
  predictions: Prediction[];
  loading: boolean;
  opened: boolean;
}

export function PredictionInput({ onPredictionClick, country, onChange, ...props }: PredictionInputProps) {
  const { language } = useSelector((store: ReduxState) => ({
    language: store.app.language,
  }));
  const predictionService = useRef(
    new LocationPrediction({
      language,
      country,
    }),
  );
  const ref = React.useRef<HTMLDivElement>(null);
  const [state, setState] = React.useState<State>({
    value: '',
    loading: false,
    opened: false,
    predictions: [],
  });

  const handleChange = useCallback(
    (value: string) => {
      setState(prev => ({ ...prev, value, loading: true }));
      predictionService.current.query(value);
      onChange(value);
    },
    [state.loading, onChange],
  );

  const handlePredictionClick = async (prediction: Prediction) => {
    onPredictionClick(prediction);
    setState(prev => ({ ...prev, opened: false }));
  };

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (state.value === props.value && state.predictions.length > 0) {
        setState(prev => ({ ...prev, opened: true }));
      }
      props.onFocus?.(event);
    },
    [state, props.value],
  );

  useEffect(() => {
    return predictionService.current.onPrediction(predictions => {
      setState(prev => ({
        ...prev,
        predictions,
        loading: false,
        opened: true,
      }));
    });
  }, []);

  useEffect(() => {
    predictionService.current.setOptions({ language, country });
  }, [language, country]);

  useOutsideClick(
    () => {
      if (state.opened) {
        setState(prev => ({ ...prev, opened: false }));
      }
    },
    ref,
    [state.opened],
  );

  return (
    <div className={styles.predictionInput} ref={ref}>
      <TextInput
        {...props}
        iconRight={state.loading ? <Loader /> : null}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      <div className={cn(styles.dropdown, { opened: state.opened })}>
        {state.predictions.map(prediction => (
          <div className={styles.option} key={prediction.id} onClick={() => handlePredictionClick(prediction)}>
            {prediction.name}
          </div>
        ))}
      </div>
    </div>
  );
}
