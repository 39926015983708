import React from 'react';
import cn from 'classnames';

import styles from './icon.module.scss';

interface Props {
  className?: string;
}

export function SkodaXLogo(props: Props) {
  return (
    <div className={cn(styles.logo, props.className)}>
      <svg fill="none" viewBox="0 0 1300 190" xmlns="http://www.w3.org/2000/svg">
        <path
          className="fill-primary"
          d="m0,165l18.5256-29.6408h107.4484c6.087,0,10.0567-3.9698,10.0567-10.0567v-17.2023H43.6674c-27.5237,0-39.6977-12.1739-39.6977-39.1682v-11.6446c0-20.1134,11.9093-32.2873,30.6995-32.2873h2.1172v43.4026c0,6.087,3.9698,10.0567,10.0567,10.0567h89.4521c20.3781,0,32.5521,12.1739,32.5521,32.0227v15.3497c0,26.2004-12.9679,39.1682-39.6977,39.1682H0Zm643.8787-30.1701h83.1005c13.7619,0,22.4954-8.4688,22.4954-21.7013v-36.2571c0-13.2325-8.7335-21.7013-22.4954-21.7013h-83.1005v79.6597Zm-33.346,30.1701V25h117.2405c34.934,0,55.0474,20.1134,55.0474,53.4594v33.0813c0,33.3459-20.1135,53.4594-55.0474,53.4594h-117.2405Zm-161.5989-30.1701h70.1326c12.7033,0,20.9074-7.9395,20.9074-20.3781v-38.9036c0-12.4386-8.2042-20.3781-20.9074-20.3781h-70.1326c-12.7033,0-20.9074,7.9395-20.9074,20.3781v38.9036c0,12.4386,8.2042,20.3781,20.9074,20.3781Zm70.9265,30.1701h-71.7205c-33.8754,0-53.4595-19.5841-53.4595-52.1361v-35.7278c0-32.552,19.5842-52.1361,53.4595-52.1361h71.7205c33.8754,0,53.4595,19.5841,53.4595,52.1361v35.7278c0,32.552-19.5842,52.1361-53.4595,52.1361Zm276.623,0l78.6014-140h46.314l78.6014,140h-38.1098l-13.4972-24.0832h-66.9567l-17.9963-28.5822-29.6409,52.6654h-37.3158Zm67.7507-54.2533h67.2214l-33.6107-59.5463-33.6107,59.5463ZM69.6033,54.1115l-19.0549-29.1115h115.1233l-18.5256,29.1115h-77.5428Zm135.938,110.8885V25h33.346v54.518h44.1967l57.4293-54.518h44.1967l-73.0437,69.603,74.367,70.397h-46.8433l-57.4293-55.3119h-42.8735v55.3119h-33.346Z"
        />
        <path
          className="fill-primary"
          d="m1251.9166,190l-56.1093-56.1091,24.0417-24.0416,80.1509,80.1508h-48.0834Zm-189.9166,0h48.0553l94.9447-95L1110.0553,0h-48.0553l94.9447,95-94.9447,95ZM1251.9166,0l-56.1093,56.1091,24.0417,24.0416L1300,0h-48.0834Z"
        />
      </svg>
    </div>
  );
}
